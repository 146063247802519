import React, { Component, useState, useEffect } from "react";
import Header from "../template/header";
import Footer from "../template/footer";
import Menu from "../template/menu";
import axios from "../../api/axios";

const Profile = () => {
  const [errorMsgApi, setErrMsgApi] = useState("");
  const [successMsgApi, setSuccessMsgApi] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_no, setPhoneNo] = useState("");
  useEffect(() => {
    // Fetch user data when the component mounts
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.get("profile", config); // Replace with your API endpoint
      const userData = response.data;

      setName(userData.name);
      setEmail(userData.email);
      setPhoneNo(userData.phone_no);
    } catch (error) {
      // Handle error
      console.error("Error fetching user data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
        const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    
        const response = await axios.post('profile/update', {
            name: name,
            email: email,
            phone_no: phone_no
        },config);

        if (response.data.success) {
            setSuccessMsgApi(response.data.msg);
            setErrMsgApi('');
            localStorage.setItem('userName',name);
            localStorage.setItem('userEmail',email);
        } else {
            setErrMsgApi(response.data.msg);
            setSuccessMsgApi('');
        }
    } catch (error) {
        setErrMsgApi("An error occurred while updating the profile.");
        setSuccessMsgApi('');
    }
  };
  return (
    
      <div className="wrapper">
        <Header />
        
        <div className="content-wrapper">
          <section className="content-header">
           <div className="container">
           <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Update Profile </h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item active">Profile Update</li>
                  </ol>
                </div>
              </div>
            </div>
           </div>
            {/* /.container-fluid */}
          </section>
        <div className="container">
        <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-xs-12 col-sm-12">
                  <div className="card card-primary">
                    <div className="card-header">Modify your profile</div>
                    <form onSubmit={handleSubmit}>
                      <br></br>
                      {errorMsgApi != "" ? (
                        <div class="alert alert-danger ">
                          <button
                            type="button"
                            class="close"
                            data-dismiss="alert"
                            aria-hidden="true"
                          ></button>
                          <h5>
                            <i class="icon fas fa-exclamation-triangle"></i>{" "}
                            Error!
                          </h5>
                          {errorMsgApi}
                        </div>
                      ) : (
                        ""
                      )}
                      {successMsgApi != "" ? (
                        <div class="alert alert-success ">
                          <button
                            type="button"
                            class="close"
                            data-dismiss="alert"
                            aria-hidden="true"
                          ></button>
                          <h5>Success!</h5>
                          {successMsgApi}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="card-body">
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            User Name
                          </label>
                          <input
                            required
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="form-control"
                            id="name"
                            placeholder="Please type user name"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">Email</label>
                          <input
                            required
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="form-control"
                            id="email"
                            placeholder="Please type Email"
                          />
                        </div>
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Phone Number
                          </label>
                          <input
                            required
                            type="number"
                            value={phone_no}
                            onChange={(e) => setPhoneNo(e.target.value)}
                            className="form-control"
                            id="phone_no"
                            placeholder="Please type phone no"
                          />
                        </div>
                        <div className="form-group">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>

                      {/* /.card-body */}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        </div>
        <Footer />
      </div>
    
  );
};
export default Profile;
