import React, { Component,useState,Redirect, } from 'react'
import { Link,Navigate,useNavigate  } from 'react-router-dom';
import axios from '../../api/axios';
const Header=()=>{
  const navigate = useNavigate();
const logout = ()=>
{

  localStorage.clear();
  navigate("/");
}
  return(
    <div>
<nav className="main-header navbar navbar-expand-md navbar-light navbar-dark">
  <div className="container">
    <a href="#" className="navbar-brand">
      <img src="../../dist/img/blue.png" alt="AdminLTE Logo" className="brand-image  elevation-3" style={{opacity: '1'}} />
      
    </a>
    <button className="navbar-toggler order-1" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span className="navbar-toggler-icon" />
    </button>
    <div className="collapse navbar-collapse order-3" id="navbarCollapse">
      {/* Left navbar links */}
      <ul className="navbar-nav">
        <li className="nav-item">
          <Link to="/dashboard" className="nav-link">Dashboard</Link>
        </li>
        <li className="nav-item">
          <Link to="/card-info" className="nav-link">Card Information</Link>
        </li>
      
        
      </ul>
      {/* SEARCH FORM */}
      
    </div>
    {/* Right navbar links */}
    <ul className="order-1 order-md-3 navbar-nav navbar-no-expand ml-auto">
        {/* Navbar Search */}
        
        {/* Messages Dropdown Menu */}
       
        {/* Notifications Dropdown Menu */}
        <li className="nav-item dropdown">
          <a className="nav-link" data-toggle="dropdown" href="#">
            <i className="far fa-user" />   {localStorage.getItem('userName')} <i className="fa fa-arrow-down" /> 
            
          </a>
          <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
            
            <div className="dropdown-divider" />
            <Link to="/password-change" className="dropdown-item">
              <i className="fa fa-key" /> Change password
              
            </Link>
            <div className="dropdown-divider" />
            <Link to="/profile" className="dropdown-item">
              <i className="fas fa-user mr-2" /> Profile update
              
            </Link>
            <div className="dropdown-divider" />
            <a href="#" className="dropdown-item" onClick={logout}>
              <i className="fas fa-sign-out-alt" /> Logout
              
            </a>
            <div className="dropdown-divider" />
            
          </div>
        </li>
       
       
      </ul>

  </div>
</nav>

  </div>
  );
}
export default Header
