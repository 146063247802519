import React, { Component } from 'react'
import Header from '../template/header';
import Menu from '../template/menu';
import Dashboard from '../template/dashboard';
import Footer from '../template/footer';
export default class template extends Component {
  render() {
    return (
       
        <div className='wrapper'>
  {/* Navbar */}
  <Header/>
  {/* /.navbar */}
  {/* Content Wrapper. Contains page content */}
  <Dashboard/>
  {/* /.content-wrapper */}
  {/* Control Sidebar */}
  <aside className="control-sidebar control-sidebar-dark">
    {/* Control sidebar content goes here */}
  </aside>
  {/* /.control-sidebar */}
  {/* Main Footer */}
  <Footer/>
</div>


    )
  }
}
