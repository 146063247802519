
import React, { Component,useState,Redirect } from 'react'
import { Link,Navigate,useNavigate  } from 'react-router-dom';
import axios from '../../api/axios';


const Register = () => {
  const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
  const [email,setEmail]=useState('');
  const [name,setName]=useState('');
  const [pass,setPass]=useState('');
  const [reTypePass,setReTypePass]=useState();
  const[phoneNumber,setPhoneNumber]=useState();
  const [errMsgApi, setErrMsgApi] = useState('');
  const [success, setSuccess] = useState(false);
  const[errorMessage,setErrorMessage]=useState('');
  const[redirect,setRedirect]=useState(true);
  const navigate = useNavigate();
  

  const[otp,setOtp]=useState();
  const REGISTER_URL='register';
  const VERIFY_ME='verifyMe';
  
  const divStyle={
    display:'block',
  };
  const handleSubmitOtp=async(e)=>{
    e.preventDefault();
    try{
      const response=await axios.post(VERIFY_ME,JSON.stringify({
        phone_no:phoneNumber,OTP:otp
      }),{
        headers:{'Content-Type':'application/json'}
      });
    //  alert();
    if(response.data.success==true)
    {
      navigate("/");
     // window.location.reload();
      setRedirect(true);
      return <Navigate to="/"/>
      //setOtpSuccess(true);
    }
    if(response.data.success==0)
    {
      //return <Navigate to="/"/>
     // navigate("/");
     // window.location.reload();
      setErrMsgApi(response.data.msg);
      
    }

    
    }
    catch(err){
      setErrMsgApi('Invalid OTP submission');
    }
    return <Navigate to="/"/> 
  }
  const handleSubmit=async (e)=>{
    if(pass!==reTypePass)
    {
      setErrorMessage('Passwords do not match');
    }
    else{
      setErrorMessage('');
    }
    e.preventDefault();
    if(errorMessage=='')
    {
      try{
        const response=await axios.post(REGISTER_URL,JSON.stringify({
          email,name,password:pass,phone_no:phoneNumber
        }),{
          headers:{'Content-Type':'application/json'}
        });
        console.log("success");
        //setErrMsgApi('Please check email and phone numbers');
        console.log(response.data);
        setSuccess(true);
      }catch(err){
        setErrMsgApi('Please check email and phone numberd! Minimum password length is 6');
      }
    }
    
    //setSuccess(true);

   // console.log(email +'--'+ pass);
  }
  
  return (
   
<body className="register-page">
<div className="register-box">
  <div className="register-logo">
    
   
  </div>
  <div className="card card-dark">
  <div className='card-header text-center'>
    <img src="../../dist/img/blue.png" alt="AdminLTE Logo"  className="brand-image" style={{opacity: '1',maxHeight:'42px'}} /><br></br>
    </div>
    <div className="card-body register-card-body">
    
      { errMsgApi!='' ?
        <div class="alert alert-danger alert-dismissible">
        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
        <h5><i class="icon fas fa-exclamation-triangle"></i> Error!</h5>
        {errMsgApi}
        </div> : ''
      }
    
      <p className="login-box-msg">Register a new membership </p>
      <>
      {success ? (
  <form onSubmit={handleSubmitOtp}  method="post">
     <div className="input-group mb-3">
          <input type="number" className="form-control" required  onChange={(e)=>setOtp(e.target.value)} placeholder="Please enter otp" />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-user" />
            </div>
          </div>
        </div>
        <button className='btn btn-primary btn-md'>Submit OTP</button>
  </form>      
      ):( 
  <form onSubmit={handleSubmit}  method="post">
        <div className="input-group mb-3">
          <input type="text" className="form-control" required  onChange={(e)=>setName(e.target.value)} placeholder="Full name" />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-user" />
            </div>
          </div>
        </div>
        <div className="input-group mb-3">
          <input type="email" className="form-control" onChange={(e)=>setEmail(e.target.value)} placeholder="Email" />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-envelope" />
            </div>
          </div>
        </div>
        <div className="input-group mb-3">
          <input type="number" className="form-control"  onChange={(e)=>setPhoneNumber(e.target.value)} placeholder="Phone Number" />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-phone" />
            </div>
          </div>
        </div>
        <div className="input-group mb-3">
          <input type="password" className="form-control" onChange={(e)=>setPass(e.target.value)} placeholder="Password" />
          <div className="input-group-append">
            <div className="input-group-text">
              <span className="fas fa-lock" />
            </div>
          </div>
        </div>
       
        <div>
      <div className="input-group mb-3">
      <input type="password" className="form-control" onChange={(e)=>setReTypePass(e.target.value)} placeholder="Retype password" />
        <div className="input-group-append">
          <div className="input-group-text">
            <span className="fas fa-lock" />
          </div>
        </div><br></br>
       
      </div>
      {errorMessage && <div className="invalid-feedback" style={divStyle}>{errorMessage}</div>}
     
    </div>
        <div className="row">
          <div className="col-8">
            <div className="icheck-primary">
              {/* <input type="checkbox" id="agreeTerms" name="terms" defaultValue="agree" />
              <label htmlFor="agreeTerms">
                I agree to the <a href="#">terms</a>
              </label> */}
            </div>
          </div>
          {/* /.col */}
          <div className="col-4">
            <button type="submit" className="btn btn-primary btn-block">Register</button>
          </div>
          {/* /.col */}
        </div>
      </form>
      )}
      
      </>
      <Link to="/">I already have a membership</Link>
      
    </div>
    {/* /.form-box */}
  </div>{/* /.card */}
</div>




</body>
  )
}
export default Register
