import React, { Component,useState } from 'react'
import { Link,useNavigate } from 'react-router-dom';
import axios from '../../api/axios';

const Login = () => {
  const [email,setEmail]=useState('');
  const [pass,setPass]=useState('');
  const LOGIN_URI='login';
  const navigate = useNavigate();
  const [errMsgApi,setErrMsgApi]=useState('');

  const handleSubmit=async(e)=>{
    e.preventDefault();
    try{
      const response=await axios.post(LOGIN_URI,JSON.stringify({
        email,password:pass
      }),{
        headers:{'Content-Type':'application/json'}
      });
    //  alert();
    console.log(response.data);
    if(response.data.success==true)
    {
      localStorage.setItem('accessToken',response.data.data.token);
      localStorage.setItem('userName',response.data.data.name);
      localStorage.setItem('userImage',response.data.data.imageUri);
      localStorage.setItem('userEmail',response.data.data.email);
      
      navigate("/dashboard");
    }
    if(response.data.success==0)
    {
      //return <Navigate to="/"/>
     // navigate("/");
     // window.location.reload();
      setErrMsgApi(response.data.msg);
      
    }

    
    }
    catch(err){
      setErrMsgApi('Invalid username and password');
    }
   
  }


  // const handleSubmit=(e)=>{
  //   e.preventDefault();
  //   console.log(email +'--'+ pass);
  // }
  return (
    <body className="hold-transition login-page">
    <div className="login-box">
{/* /.login-logo */}
<div className="card  card-dark">
 <div className="card-header text-center" >
 <img src="../../dist/img/blue.png" alt="AdminLTE Logo"  className="brand-image" style={{opacity: '1',maxHeight:'42px'}} /><br></br>
 </div>
 <div className="card-body">
 { errMsgApi!='' ?
        <div class="alert alert-danger alert-dismissible">
        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
        <h5><i class="icon fas fa-exclamation-triangle"></i> Error!</h5>
        {errMsgApi}
        </div> : ''
      }
   <p className="login-box-msg">Sign in to start your session</p>
   <form onSubmit={handleSubmit} method="post">
     <div className="input-group mb-3">
       <input type="email" value={email} onChange={(e)=>setEmail(e.target.value)} className="form-control" placeholder="Email" />
       <div className="input-group-append">
         <div className="input-group-text">
           <span className="fas fa-envelope" />
         </div>
       </div>
     </div>
     <div className="input-group mb-3">
       <input type="password" value={pass} onChange={(e)=>setPass(e.target.value)} className="form-control" placeholder="Password" />
       <div className="input-group-append">
         <div className="input-group-text">
           <span className="fas fa-lock" />
         </div>
       </div>
     </div>
     <div className="row">
       <div className="col-8">
        
       </div>
       {/* /.col */}
       <div className="col-4">
         <button type="submit" className="btn btn-primary btn-block">Sign In</button>
       </div>
       {/* /.col */}
     </div>
   </form>
  
   {/* /.social-auth-links */}
   <p className="mb-1">
     <Link to="forgot-password">I forgot my password</Link>
   </p>
   <p className="mb-0">
   <Link to="/register">Register a new membership</Link>
     
   </p>
 </div>
 {/* /.card-body */}
</div>
{/* /.card */}
</div>


     </body>
  )
}

export default Login
