import React, { Component,useState } from "react";
import Header from "../template/header";
import Footer from "../template/footer";
import Menu from "../template/menu";
import axios from "../../api/axios";

//{"old_password":"12345678","password":"123456","password_confirmation":"123456"}
const Password_change = () => {
    const [old_password,setOldPass]=useState('');
    const [password,setPass]=useState('');
    const [password_confirmation,setPassConf]=useState('');
    const [errorMsgApi,setErrMsgApi]=useState('');
    const [successMsgApi,setSuccessMsgApi]=useState('');

    const PASS_CHANGE_URI='profile/password/update'
    const handleSubmit=async(e)=>{
        e.preventDefault();
        const token = localStorage.getItem('accessToken');
        try{
            const response=await axios.post(PASS_CHANGE_URI,JSON.stringify({
                old_password,password,password_confirmation
            }),{
              headers:{
                'Content-Type':'application/json',
                'Authorization': 'Bearer '+token
            }
            });
          //  alert();
          console.log(response.data);
          if(response.data.success==true)
          {
            setSuccessMsgApi(response.data.msg);
            setErrMsgApi('');
            setPass('');
            setPassConf('');
            setOldPass('');
            
           // navigate("/dashboard");
          }
          if(response.data.success==false)
          {
            setErrMsgApi(response.data.msg);
            setSuccessMsgApi('');
            //return <Navigate to="/"/>
           // navigate("/");
           // window.location.reload();
            //setErrMsgApi(response.data.msg);
            
          }
   
          
          }
          catch(err){
            setErrMsgApi("The given data is invalid");
            setSuccessMsgApi('');
          }
    }
return(
    
        <div className="wrapper">
          <Header />
          
          <div className="content-wrapper">
            <section className="content-header">
             <div className="container">
             <div className="container-fluid">
                <div className="row mb-2">
                  <div className="col-sm-6">
                    <h1>Change Password </h1>
                  </div>
                  <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right">
                      <li className="breadcrumb-item active">
                        Change Password
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
             </div>
              {/* /.container-fluid */}
            </section>
          <div className="container">
          <section className="content">
              <div className="container-fluid">
               <div className="row">
                <div className="col-md-12 col-xs-12 col-sm-12">
                <div className="card card-primary">
                  <div className="card-header">Change your password</div>
                  <form onSubmit={handleSubmit}>
                    <br></br>
                    {errorMsgApi!=''? <div class="alert alert-danger ">
        <button type="button" class="close" data-dismiss="alert" aria-hidden="true"></button>
        <h5><i class="icon fas fa-exclamation-triangle"></i> Error!</h5>
        {errorMsgApi}
        </div> : ''}
        {successMsgApi!=''? <div class="alert alert-success ">
        <button type="button" class="close" data-dismiss="alert" aria-hidden="true"></button>
        <h5>Success!</h5>
        {successMsgApi}
        </div> : ''}
                     <div className="card-body">
                      
                     <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Old password</label>
                        <input
                        required
                          type="password"
                          value={old_password}
                          onChange={(e)=>setOldPass(e.target.value)}
                          className="form-control"
                          id="oldPassword"
                          placeholder="Please type old password"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">New password</label>
                        <input
                        required
                          type="password"
                          value={password}
                          onChange={(e)=>setPass(e.target.value)}
                          className="form-control"
                          id="newPassword"
                          placeholder="Please type new password"
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="exampleInputPassword1">Password Confirmation</label>
                        <input
                        required
                          type="password"
                          value={password_confirmation}
                          onChange={(e)=>setPassConf(e.target.value)}
                          className="form-control"
                          id="passwordConf"
                          placeholder="Please Re-type new password"
                        />
                      </div>
                      <div className="form-group">
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>
                      </div>
                     </div>
                     
                      
                   
                    {/* /.card-body */}
                    
                  </form>
                </div>
                </div>
               </div>
              </div>
            </section>
          </div>
          </div>
          <Footer />
        </div>
      
)
}
export default Password_change

