import React, { Component } from 'react'

export default class footer extends Component {
  render() {
    return (
      <div className='container'>
        <div className="float-right d-none d-sm-inline">
        All rights Reserved @2023
    </div>
        
      </div>
    )
  }
}
