import React, { Component, useState, useEffect } from "react";
import Header from "../template/header";
import Footer from "../template/footer";
import Menu from "../template/menu";
import axios from "../../api/axios";

const Cardinfo = () => {
    //id, user_id, card_no, acc_name, ccv, exp_dt
  const [errorMsgApi, setErrMsgApi] = useState("");
  const [successMsgApi, setSuccessMsgApi] = useState("");
  const [card_no, setCardNo] = useState("");
  const [acc_name, setAcctName] = useState("");
  const [exp_dt, setExpDt] = useState("");
  const [ccv, setCcv] = useState("");
  useEffect(() => {
    // Fetch user data when the component mounts
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    const token = localStorage.getItem("accessToken");
    const config = {
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };
    try {
      const response = await axios.get("userCardInfo", config); // Replace with your API endpoint
      const cardData = response.data;
      
    if(cardData.data!=null)
    {
        setCardNo(cardData.data.card_no);
        setAcctName(cardData.data.acc_name);
        setExpDt(cardData.data.exp_dt);
        setCcv(cardData.data.ccv);
       // alert("Not null")
    }
    else{
        //alert("Null")
    }
    //   setName(userData.name);
    //   setEmail(userData.email);
    //   setPhoneNo(userData.phone_no);
    } catch (error) {
      // Handle error
      console.error("Error fetching user data:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("accessToken");
      const config = {
        headers: {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        },
      };

      const response = await axios.post(
        "userCardInfoSaveOrUpdate",
        {
          card_no,acc_name,ccv,exp_dt
        },
        config
      );

      if (response.data.success) {
        setSuccessMsgApi(response.data.msg);
        setErrMsgApi("");
       
      } else {
        setErrMsgApi(response.data.msg);
        setSuccessMsgApi("");
      }
    } catch (error) {
      setErrMsgApi("An error occurred while updating the profile.");
      setSuccessMsgApi("");
    }
  };
  return (
   
      <div className="wrapper">
        <Header />
   
        <div className="content-wrapper">
          <section className="content-header">
            <div className="container">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>Card Information </h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item active">Card Info</li>
                  </ol>
                </div>
              </div>
            </div>
            </div>
            {/* /.container-fluid */}
          </section>
          <div className="container">
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12 col-xs-12 col-sm-12">
                  <div className="card card-primary">
                    <div className="card-header">Modify your card info</div>
                    <form onSubmit={handleSubmit}>
                      <div className="card-body">
                      <br></br>
                      {errorMsgApi != "" ? (
                        <div class="alert alert-danger ">
                          <button
                            type="button"
                            class="close"
                            data-dismiss="alert"
                            aria-hidden="true"
                          ></button>
                          <h5>
                            <i class="icon fas fa-exclamation-triangle"></i>{" "}
                            Error!
                          </h5>
                          {errorMsgApi}
                        </div>
                      ) : (
                        ""
                      )}
                      {successMsgApi != "" ? (
                        <div class="alert alert-success ">
                          <button
                            type="button"
                            class="close"
                            data-dismiss="alert"
                            aria-hidden="true"
                          ></button>
                          <h5>Success!</h5>
                          {successMsgApi}
                        </div>
                      ) : (
                        ""
                      )}
                     <div className="row">
                        <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Card Number (*)
                          </label>
                          <input
                            required
                            type="text"
                            value={card_no}
                            onChange={(e) => setCardNo(e.target.value)}
                            className="form-control"
                            id="name"
                            maxlength="19"
                            minLength="16"
                            placeholder="Example: 1234 5678 9101 1123"
                          />
                        </div>
                        </div>
                        <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Account Name (*)
                          </label>
                          <input
                            required
                            type="text"
                            value={acc_name}
                            onChange={(e) => setAcctName(e.target.value)}
                            className="form-control"
                            id="name"
                            placeholder="Example : MR Account Holder"
                          />
                        </div>
                        </div>
                     </div>
                     <div className="row">
                        <div className="col-md-6 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            Expiry Date (*)
                          </label>
                          <input
                            required
                            type="text"
                            value={exp_dt}
                            onChange={(e) => setExpDt(e.target.value)}
                            className="form-control"
                            id="name"
                            maxlength="5"
                            minLength="5"
                            placeholder="Example : 04/30"
                          />
                        </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                        <div className="form-group">
                          <label htmlFor="exampleInputPassword1">
                            CCV
                          </label>
                          <input
                         
                            type="text"
                            value={ccv}
                            onChange={(e) => setCcv(e.target.value)}
                            className="form-control"
                            id="name"
                            maxlength="3"
                            minLength="3"
                            placeholder="Example : 123"
                          />
                        </div>
                            </div>
                     </div>
                     <div className="row">
                        <div className="col-md-6">
                        <div className="form-group">
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                        </div>
                     </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </section>
          </div>
        </div>
        <Footer />
      </div>

  );
};
export default Cardinfo;
