import logo from './logo.svg';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';

import Template  from './components/template/template';
import Error from './components/template/error';
import Post from './components/template/test/post';
import Login from './components/authentication/login';
import Register from './components/authentication/register';
import ProtectedRoutesContent from './components/protectiveRoutes';
import Password_change from './components/profile/password_change';
import Profile from './components/profile/profile';
import Cardinfo from './components/profile/card_info';
import Forgot_password from './components/authentication/forgot_password';
function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route element={<ProtectedRoutesContent/>}>
      <Route path='/dashboard' element={<Template/>}></Route>
      <Route path='/password-change' element={<Password_change/>}></Route>
      <Route path='/profile' element={<Profile/>}></Route>
      <Route path='/card-info' element={<Cardinfo/>}></Route>
      </Route>
      <Route path='/register' element={<Register/>}></Route>
      <Route path='/forgot-password' element={<Forgot_password/>}></Route>
      <Route index element={<Login/>}></Route>
     
      
      <Route path='*' element={<Error/>}></Route>
    </Routes>
    {/* <Template/> */}
    </BrowserRouter>
    
  );
}

export default App;
