import React from 'react';
import { Outlet,Navigate } from 'react-router-dom';
import Login from './authentication/login';

const useAuth = () => {
  //alert(localStorage.getItem('accessToken'));
  if(localStorage.getItem('accessToken')!=null)
  {
    
    const user = { loggedIn: true };
    return user && user.loggedIn;
  }
  else{
    const user = { loggedIn: false };
    return user && user.loggedIn;
  }
  
  
};

const ProtectedRoutesContent = () => {
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Navigate to="/"/>;
};

export default ProtectedRoutesContent;