import React, { Component } from 'react'
import axios from '../../api/axios';
import { format, parseISO } from 'date-fns';

class Dashboard extends Component{
  constructor(props) {
    super(props);
    this.state = {
      wait: [],
      current: [],
      complete: [],
      cancel: [],
    };
  }
  componentDidMount()
  {
    
    const token = localStorage.getItem('accessToken');
    const config = {
      headers: {
        Authorization: 'Bearer ' + token,
        'Content-Type': 'application/json',
      },
    };

    axios
      .get('booking', config)
      .then(response => {
        const { wait, current, complete, cancel } = response.data.data;
        this.setState({
          wait,
          current,
          complete,
          cancel,
        });
      })
      .catch(error => {
        console.error('Error fetching Data', error);
      });

  }
  getOnlyDate(dateTime)
  {
    let parsedDate = parseISO(dateTime);
    let formattedDate = format(parsedDate, 'MMMM dd, yyyy HH:mm a');
    return formattedDate;
  }
  renderBookingRows(bookings) {
    let i=1;
    return bookings.map(item => (

      
      <tr key={item.id}>
        <td>{i++}</td>
        <td>{item.booking_id}</td>
        <td>{item.model.reg_number}</td>
        <td>{format(parseISO(item.start_time), 'dd-MMM-yyyy')}</td>
        <td>{format(parseISO(item.start_time), 'HH:mm a')}</td>
        <td>{format(parseISO(item.end_time), 'HH:mm a')}</td>
        
      </tr>
    ));
  }
  render(){
    const { data } = this.state;
    const { wait, current, complete, cancel } = this.state;
    return (
      <div>
      <div className="content-wrapper">
        {/* Content Header (Page header) */}
        <section className="content-header">
          <div className='container'>
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Profile</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  
                  <li className="breadcrumb-item active">User Profile</li>
                </ol>
              </div>
            </div>
          </div>
          </div>
        </section>
        {/* Main content */}
       <div className='container'>
       <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3">
                {/* Profile Image */}
                <div className="card card-primary card-outline">
                  <div className="card-body box-profile">
                    <div className="text-center">
                      <img className="profile-user-img img-fluid img-circle" src="../../dist/img/default-user-icon-8.jpg" alt="User profile picture" />
                    </div>
                    <h3 className="profile-username text-center">{localStorage.getItem('userName')}</h3>
                    <p className="text-muted text-center">{localStorage.getItem('userEmail')}</p>
                    <ul className="list-group list-group-unbordered mb-3">
                      <li className="list-group-item">
                        <b>Pending</b> <a className="float-right">{wait.length}</a>
                      </li>
                      <li className="list-group-item">
                        <b>Current</b> <a className="float-right">{current.length}</a>
                      </li>
                      <li className="list-group-item">
                        <b>Completed</b> <a className="float-right">{complete.length}</a>
                      </li>
                      <li className="list-group-item">
                        <b>Cancelled</b> <a className="float-right">{cancel.length}</a>
                      </li>
                    </ul>
                    
                  </div>
                  {/* /.card-body */}
                </div>
                {/* /.card */}
                {/* About Me Box */}
               
                {/* /.card */}
              </div>
              {/* /.col */}
              <div className="col-md-9">
                <div className="card">
                  <div className="card-header p-2">
                    <ul className="nav nav-pills">
                      <li className="nav-item"><a className="nav-link active" href="#pending" data-toggle="tab">Pending</a></li>
                      <li className="nav-item"><a className="nav-link" href="#current" data-toggle="tab">Current</a></li>
                      <li className="nav-item"><a className="nav-link" href="#completed" data-toggle="tab">Completed</a></li>
                      <li className="nav-item"><a className="nav-link" href="#cancelled" data-toggle="tab">Cancelled</a></li>
                    </ul>
                  </div>{/* /.card-header */}
                  <div className="card-body">
                    <div className="tab-content">
                    <div className="active tab-pane" id="pending">
                      <table className='table table-bordered table-stripped table sm'>
                      <thead>
                        <tr>
                          <th>Sl</th>
                          <th>Order Id</th>
                          <th>Reg Number</th>
                          <th>Booking Date</th>
                          <th>Start time</th>
                          <th>End Time</th>
                          
                        </tr>
                      </thead>
                      <tbody>

                      {this.renderBookingRows(wait)}
                        
                        
                        
                        
                      </tbody>
                      </table>
                      </div>
                      <div className=" tab-pane" id="current">
                      <table className='table table-bordered table-stripped table sm'>
                      <thead>
                        <tr>
                          <th>Sl</th>
                          <th>Order Id</th>
                          <th>Reg Number</th>
                          <th>Booking Date</th>
                          <th>Start time</th>
                          <th>End Time</th>
                          
                        </tr>
                      </thead>
                      <tbody>

                      {this.renderBookingRows(current)}
                        
                        
                        
                        
                      </tbody>
                      </table>
                      </div>
                      <div className=" tab-pane" id="completed">
                      <table className='table table-bordered table-stripped table sm'>
                      <thead>
                        <tr>
                          <th>Sl</th>
                          <th>Order Id</th>
                          <th>Reg Number</th>
                          <th>Booking Date</th>
                          <th>Start time</th>
                          <th>End Time</th>
                          
                        </tr>
                      </thead>
                      <tbody>

                      {this.renderBookingRows(complete)}
                        
                        
                        
                        
                      </tbody>
                      </table>
                      </div>
                      <div className=" tab-pane" id="cancelled">
                      <table className='table table-bordered table-stripped table sm'>
                      <thead>
                        <tr>
                          <th>Sl</th>
                          <th>Order Id</th>
                          <th>Reg Number</th>
                          <th>Booking Date</th>
                          <th>Start time</th>
                          <th>End Time</th>
                          
                        </tr>
                      </thead>
                      <tbody>

                      {this.renderBookingRows(cancel)}
                        
                        
                        
                        
                      </tbody>
                      </table>
                      </div>
     
                    </div>
                    {/* /.tab-content */}
                  </div>{/* /.card-body */}
                </div>
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>{/* /.container-fluid */}
        </section>
       </div>
        {/* /.content */}
      </div>
    </div>
    );
  }
}
export default Dashboard